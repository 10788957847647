import React from 'react';

import './styles.scss';

const ScrollBar = () => {

  return (
    <>
    </>
  );
};

export default ScrollBar;